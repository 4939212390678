// --------------------------------

// (START) Global editor code https://codyhouse.co/ds/globals/forms

// --------------------------------

.form-control {
  --form-control-font-size: 1em;
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-xxs);
  --form-control-radius: 0.25em;
  background-color: var(--color-bg);
  border: 2px solid var(--color-contrast-low);
  line-height: 1.2;
  transition: all 0.2s ease;

  &::placeholder {
    opacity: 1;  
    color: var(--color-contrast-medium);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
    outline: none;
  }
}

.form-control--disabled, .form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"], .form-control--error {
  border-color: var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  font-size: var(--text-md);
}

.form-label {
  font-size: var(--text-sm);
}

// --------------------------------

// (END) Global editor code

// --------------------------------