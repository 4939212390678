//
// TYPO3
// --------------------------------------------------
.rte-lead {
	font-size: var(--text-xl);
}

.rte-highlighted {
	font-size: var(--text-md);
}

.rte-small {
	font-size: var(--text-sm);
	color: var(--color-contrast-medium);
}

//
// Default
// --------------------------------------------------
.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.min-height-75vh {
	min-height: 75vh;
}
.min-height-50vh {
	min-height: 50vh;
}

.thumbnail {
	img {
		background-color: #fff;
		border: 1px solid var(--color-contrast-low);
	}

	a,
	img,
	div {
		transition: all 0.3s;
	}

	a:hover {
		.filename_url {
			color: var(--color-primary);
		}
		img {
			transform: scale(1.025);
		}
	}
}
